/**
 * @generated SignedSource<<1ef40abac8f8df758ff10851c817691d>>
 * @relayHash f8fc5c91483316dfc3fd051816e3a589
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f8fc5c91483316dfc3fd051816e3a589

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPreloadedContextQuery$variables = Record<PropertyKey, never>;
export type AppPreloadedContextQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly nickname: string;
    readonly originalId: number;
    readonly profileImageUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"bizProfileBroker_viewer">;
  } | null;
};
export type AppPreloadedContextQuery = {
  response: AppPreloadedContextQuery$data;
  variables: AppPreloadedContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BizProfileBroker",
  "kind": "LinkedField",
  "name": "bizProfileBroker",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizProfile",
      "kind": "LinkedField",
      "name": "bizProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localProfileId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "businessRegistrationNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jibunAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseOwnerContact",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseOwnerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseRegistrationNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPreloadedContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "bizProfileBroker_viewer",
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppPreloadedContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f8fc5c91483316dfc3fd051816e3a589",
    "metadata": {},
    "name": "AppPreloadedContextQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2609aec4d00301c3bc59908af5f787ea";

export default node;
