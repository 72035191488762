import { useCallback, useEffect } from 'react';
import { LocalStorage } from 'utils/Storage';
import NudgeEventCollector from 'utils/EventCollector';
import { NudgeState, NudgeType, tooltipState } from 'store/event';
import Logger from 'utils/Logger';
import { useAtomCallback } from 'jotai/utils';

const useNudgeController = () => {
  const updateTooltipState = useAtomCallback(
    useCallback(
      (_, set) => (nudgeState: NudgeState) => {
        LocalStorage.setNudgeHistory(nudgeState.type);
        set(tooltipState(nudgeState.type), nudgeState.value);
        Logger.impression({
          name: nudgeState.type,
          description: `${nudgeState.type} 넛지가 보여진 경우 impression 이벤트예요.`,
        });
      },
      []
    )
  );
  useEffect(() => {
    const checkLocalStorage = (type: NudgeType, now: Date): boolean => {
      const history = LocalStorage.getNudgeHistory();

      if (
        !history[type] ||
        new Date(history[type]) < new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14)
      ) {
        return true;
      }

      return false;
    };

    const checkNudge = (nudgeState: NudgeState) => {
      const now = new Date();

      if (nudgeState.type === 'MyPage') {
        if (
          (LocalStorage.getEverWatched() || LocalStorage.getFirstNewArticleBannerShowed()) &&
          !LocalStorage.getEverVisited()
        ) {
          LocalStorage.setEverVisited();
        } else {
          return;
        }
      }
      if (checkLocalStorage(nudgeState.type, now)) {
        updateTooltipState()(nudgeState);
      }
    };

    NudgeEventCollector.addListener(checkNudge);
    return () => {
      NudgeEventCollector.removeListener(checkNudge);
    };
  }, []);
};

export default useNudgeController;
