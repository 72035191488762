import { atomEffect } from 'jotai-effect';
import { atomWithLazy } from 'jotai/utils';
import { LocalStorage } from 'utils/Storage';

// AB 테스트용
// Key 값 기록을 위해 더이상 사용하지 않는 필드도 남겨둠
export const ClientGroupList = {
  DO_NOT_USE: [],
  Consultation: [],
  ConsultSection: [],
  LandTitle: ['visible', 'hidden'],
  PriceFooter: ['control', 'treatment'],
} as const;
type ClientGroupList = typeof ClientGroupList;

type MapArrayType<T extends Record<string, readonly string[]>> = {
  [K in keyof T]: T[K][number];
};

export type ClientUserGroup = MapArrayType<ClientGroupList>;
// eslint-disable-next-line @typescript-eslint/ban-types
type ServerGroup = {};
export type UserGroup = ClientUserGroup & ServerGroup;

const getOrCreateClientGroup = <T extends keyof ClientUserGroup>(name: T) => {
  const lastGroup = LocalStorage.getGroup(name);
  if (lastGroup) {
    return lastGroup;
  }

  const groupList = Object.values(ClientGroupList[name]);
  const group = groupList[Math.floor(Math.random() * groupList.length)];
  LocalStorage.setGroup(name, group as ClientUserGroup[T]);
  return group;
};

const groupState = atomWithLazy(() => {
  const clientGroups = Object.keys(ClientGroupList).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getOrCreateClientGroup(key as keyof ClientUserGroup),
    }),
    {} as ClientUserGroup
  );

  return {
    ...clientGroups,
  };
});

export const groupToLocalStorageEffect = atomEffect((get) => {
  const newGroup = get(groupState);

  Object.entries(newGroup).forEach(([key, value]) => {
    if (Object.keys(ClientGroupList).includes(key)) {
      LocalStorage.setGroup(key as keyof ClientUserGroup, value);
    }
  });
});

export default groupState;
