import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { AnimatePresence } from 'framer-motion';
import { IconCloseRegular } from '@seed-design/react-icon';
import { HStack, Stack } from '@chakra-ui/layout';
import { zIndices } from 'constants/zIndices';
import useDarkMode from 'hooks/useDarkmode';
import { css } from '@emotion/react';
import Logger, { type LoggerComponentProps } from 'utils/Logger';
import VisibilityMotion from 'components/Base/VisibilityMotion';
import { wordBreakText } from 'styles/mixin';
import ConditionalWrapper from 'components/Base/ConditionalWrapper';
import Divider from 'components/Base/Divider';
import useCombinedRefs from 'hooks/useCombinedRefs';
import PageLevelModal from 'components/Base/Portal/PagePortal';
import Dim from 'components/Base/Dim';
import Handler from './Handler';

export type BottomSheetProps = {
  isOpen: boolean;
  isPageLevel?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  animatePresence?: boolean;
  showCloseButton?: boolean;
  showDivider?: boolean;
  showDimmed?: boolean;
  closeOnDimClick?: boolean;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  scrollRef?: React.RefObject<HTMLDivElement>;
  handle?: {
    minHeight: number;
  };
  onClose: () => void;
} & LoggerComponentProps;

function BottomSheet({
  isOpen,
  title,
  description,
  isPageLevel = true,
  showCloseButton = true,
  closeOnDimClick = true,
  animatePresence = true,
  showDimmed = true,
  showDivider,
  onClose,
  children,
  footer,
  event,
  scrollRef: scrollRefProps,
  contentProps,
  handle,
  ...props
}: PropsWithChildren<BottomSheetProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const mergedScrollRef = useCombinedRefs(scrollRef, scrollRefProps);
  const [showGradient, setShowGradient] = useState(false);
  const isDarkMode = useDarkMode();

  const handleDimClick = () => {
    if (closeOnDimClick) {
      event &&
        Logger.click(
          {
            name: `click_bottom_sheet_dim`,
            description: `bottomsheet dim 클릭`,
            event_type: event.type,
            ...event.params,
          },
          event.option
        );

      onClose();
    }
  };

  useLayoutEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    const $scroll = scrollRef.current;

    const handleScroll = () => {
      const hasScroll = $scroll.scrollHeight > $scroll.clientHeight;
      const isScrollEnd = $scroll.scrollHeight - $scroll.scrollTop === $scroll.clientHeight;
      setShowGradient(hasScroll && !isScrollEnd);
    };

    handleScroll();
    $scroll.addEventListener('scroll', handleScroll);

    return () => $scroll.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && event) {
      Logger.impression(
        {
          name: `bottom_sheet`,
          description: `bottomsheet 노출`,
          event_type: event.type,
          ...event.params,
        },
        event.option
      );
    }
  }, [isOpen]);

  return (
    <>
      <ConditionalWrapper
        condition={animatePresence}
        wrapper={(children) => <AnimatePresence>{children}</AnimatePresence>}
      >
        {isOpen && (
          <ConditionalWrapper
            condition={isPageLevel}
            wrapper={(children) => (
              <PageLevelModal
                isOpen
                onClose={onClose}
                onDimClick={handleDimClick}
                closeOnDimClick={closeOnDimClick}
              >
                {showDimmed && <PageLevelModal.Dim />}
                <PageLevelModal.Body>{children}</PageLevelModal.Body>
              </PageLevelModal>
            )}
          >
            {!isPageLevel && showDimmed && <Dim />}
            <Styled.Wrapper
              ref={wrapperRef}
              key="bottom_sheet"
              show={{
                y: 0,
              }}
              hide={{
                y: '100%',
              }}
              initial="hide"
              animate="show"
              exit="hide"
              {...props}
            >
              {handle && (
                <Handler wrapperRef={wrapperRef} minHeight={handle.minHeight} event={event} />
              )}

              <Styled.Header spacing={0}>
                {title && (
                  <>
                    <TitleEmptyPadding />
                    <Styled.TitleWrapper spacing={6}>
                      <Styled.Title>{title}</Styled.Title>
                      {description && <Styled.Description>{description}</Styled.Description>}
                    </Styled.TitleWrapper>
                    <TitleEmptyPadding />
                  </>
                )}
                {showCloseButton && (
                  <Styled.CloseButton onClick={onClose}>
                    <IconCloseRegular width={24} height={24} />
                  </Styled.CloseButton>
                )}
              </Styled.Header>
              {showDivider && <Styled.Divider />}
              <Styled.Contents
                css={[
                  showGradient &&
                    css`
                      &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 40px;
                        background: linear-gradient(
                          ${isDarkMode
                            ? '180deg, rgba(33, 33, 36, 0) 0%, rgba(33, 33, 36, 0.7) 49.48%, #212124 100%'
                            : '180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 49.48%, #FFFFFF 100%'}
                        );
                      }
                    `,
                ]}
              >
                <Styled.ContentsScroll ref={mergedScrollRef} {...contentProps}>
                  {children}
                </Styled.ContentsScroll>
              </Styled.Contents>
              {footer && <Styled.Footer>{footer}</Styled.Footer>}
            </Styled.Wrapper>
          </ConditionalWrapper>
        )}
      </ConditionalWrapper>
    </>
  );
}

export default BottomSheet;

const Styled = {
  Wrapper: styled(VisibilityMotion)`
    display: flex;
    flex-direction: column;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: calc(
      100vh - env(safe-area-inset-top) - var(--stackflow-plugin-basic-ui-app-bar-height)
    );
    overflow: hidden;
    padding-bottom: env(safe-area-inset-bottom);

    z-index: ${zIndices.modal};
    background: ${vars.$semantic.color.paperDefault};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  `,
  Header: styled(HStack)`
    position: relative;
    display: flex;
    align-items: center;

    padding: 20px 16px 16px;
    z-index: ${zIndices.docked};

    &:empty {
      padding: 0;
    }
  `,
  TitleWrapper: styled(Stack)`
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;

    ${wordBreakText}
  `,
  Title: styled.h3`
    ${vars.$semantic.typography.title2Bold};
  `,
  Divider: styled(Divider)`
    margin: 0px 16px;
  `,
  Description: styled.p`
    color: ${vars.$scale.color.gray700};
    ${vars.$semantic.typography.bodyM1Regular};
  `,
  CloseButton: styled.button`
    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    align-items: center;
    color: ${vars.$scale.color.gray900};
    padding: 3px 0px 3px 3px;
  `,

  Contents: styled.div`
    display: flex;
    position: relative;
    overflow-y: hidden;
  `,
  ContentsScroll: styled.div`
    flex: 1;
    overflow-y: auto;

    padding: 0px 16px;
  `,
  Footer: styled.div`
    padding: 12px 16px 8px;
  `,
};

const TitleEmptyPadding = styled.div`
  width: 24px;

  flex-shrink: 1;
  flex-grow: 0;
`;
