import type { bizProfileBroker_viewer$key } from '__generated__/bizProfileBroker_viewer.graphql';
import { graphql, readInlineData } from 'relay-runtime';

export const readViewerBizProfileBroker = (viewerRef: bizProfileBroker_viewer$key | null) => {
  const viewer = readInlineData(
    graphql`
      fragment bizProfileBroker_viewer on KarrotUser @inline {
        id
        bizProfileBroker {
          id
          userPhoneNumber
          bizProfile {
            id
            localProfileId
            thumbImageUrl
            businessRegistrationNumber
            name
            originalId
            createdAt
            jibunAddress
            licenseOwnerContact
            licenseOwnerName
            licenseRegistrationNumber
          }
        }
      }
    `,
    viewerRef
  );

  return viewer?.bizProfileBroker;
};
