import type { PropsOf } from '@chakra-ui/system';
import { vars } from '@seed-design/design-token';
import { IconChevronLeftRegular, IconCloseRegular } from '@seed-design/react-icon';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import { useActivity } from '@stackflow/react';
import { changeRouteStyle } from 'bridge/router';
import useCustomFlow from 'hooks/useCustomFlow';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { RouteName } from 'routes';
import { PortalDataKey } from 'utils/portal';

type AppScreenProps = PropsOf<typeof AppScreen>;
type AppScreenOptions = Omit<AppScreenProps, 'children'>;
export type AppBarProps = AppScreenProps['appBar'];

type PageContextType = {
  screenOptions?: AppScreenOptions;
  setScreenOptions: React.Dispatch<React.SetStateAction<AppScreenOptions | undefined>>;
};
const PageContext = createContext<PageContextType>({
  screenOptions: undefined,
  setScreenOptions: () => undefined,
});
export const usePageContext = () => useContext(PageContext);

const ROOT_BACK_BUTTON_ACTIVITIES: RouteName[] = ['article_detail'];

const PageLayout = (props: AppScreenProps) => {
  const [screenOptions, setScreenOptions] = useState<AppScreenOptions | undefined>();
  const { pop } = useCustomFlow();
  const { id, isRoot, isActive, name } = useActivity();
  const isRootBackButton = ROOT_BACK_BUTTON_ACTIVITIES.includes(name as RouteName);

  useEffect(() => {
    if (!isActive) return;
    if (!isRoot) return;
    // Root 인데 preventSwipeBack 옵션이 있는 경우
    if (screenOptions?.preventSwipeBack !== undefined) {
      changeRouteStyle({ backSwipable: !screenOptions.preventSwipeBack });
    }
  }, [screenOptions?.preventSwipeBack, isActive]);

  useEffect(() => {
    const container = document.querySelector(`[data-stackflow-activity-id="${id}"]`);
    const [, navbar] = [...(container?.children ?? [])] as HTMLElement[];

    navbar?.setAttribute(`data-${PortalDataKey.navbar}`, id);
  }, []);

  return (
    <PageContext.Provider value={{ screenOptions, setScreenOptions }}>
      <AppScreen
        backgroundColor={vars.$semantic.color.paperDefault}
        dimBackgroundColor={vars.$static.color.staticBlackAlpha500}
        {...props}
        {...screenOptions}
        appBar={{
          iconColor: vars.$scale.color.gray900,
          textColor: vars.$scale.color.gray900,
          border: false,
          borderSize: '0.5px',
          borderColor: vars.$semantic.color.divider3,
          ...props.appBar,
          ...screenOptions?.appBar,
          backButton: {
            renderIcon: () => <IconChevronLeftRegular size={24} />,
            ...props.appBar?.backButton,
            ...screenOptions?.appBar?.backButton,
          },
          closeButton: {
            renderIcon: () =>
              isRootBackButton ? (
                <IconChevronLeftRegular size={24} />
              ) : (
                <IconCloseRegular size={24} />
              ),
            onClick: () => pop(),
            ...props.appBar?.closeButton,
            ...screenOptions?.appBar?.closeButton,
          },
        }}
      >
        <div style={{ height: '100%', overflowY: 'auto' }}>
          {props.children}
          <div
            {...{
              [`data-${PortalDataKey.content}`]: id,
            }}
          />
        </div>
      </AppScreen>
    </PageContext.Provider>
  );
};

export default PageLayout;
