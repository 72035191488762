import {
  BuildingUsageTypes,
  ManageCostOptions,
  MonthlyPayableTradeTypes,
  ResidentSalesTypes,
  TradeTypes,
  isOneOfSalesType,
  type ManageCostPayOptionEnum,
  RequiredArticleOptionNames,
  type ArticleQualitativeItemEnum,
} from '@daangn/realty-sdk';
import { isNil } from 'lodash-es';
import { z } from 'zod';
import type { AddressKind, ArticleBuildingOrientationEnum } from 'types/schemaEnums';
import { checkIsDagaguRentalHouse } from '@daangn/realty-sdk';
import { actionToast } from 'utils/actionToast';
// @ts-expect-error emoji
import { emojiPattern } from 'regex-combined-emojis';

export const MAX_QUALITATIVE_COUNT = 3;
export const MIN_IMAGE_COUNT = 3;
export const MAX_ADDRESS_INFO_LENGTH = 30;
export const MAX_TRADE_DESCRIPTION_LENGTH = 50;

export const DefaultRequiredOptions = RequiredArticleOptionNames.map(
  (name) =>
    ({
      name,
      value: 'DONT_KNOW',
    }) as const
);

export const zodEnum = <T>(arr: T[]): [T, ...T[]] => arr as [T, ...T[]];

const ALL_WRITER_TYPES = ['BROKER', 'TENANT', 'LESSOR'] as const;
const ALL_SALES_TYPES = [
  'SPLIT_ONE_ROOM',
  'OPEN_ONE_ROOM',
  'TWO_ROOM',
  'APART',
  'OFFICETEL',
  'STORE',
  'ETC',
] as const;
export const ALL_ORIENTATIONS = [
  'SOUTH_FACING',
  'EAST_FACING',
  'WEST_FACING',
  'NORTH_FACING',
  'SOUTH_EAST_FACING',
  'SOUTH_WEST_FACING',
  'NORTH_WEST_FACING',
  'NORTH_EAST_FACING',
] as const satisfies ArticleBuildingOrientationEnum[];
export const ALL_MANAGE_COST_PAY_OPTIONS = [
  'NONE',
  'NEED_TO_CHECK',
  'USED',
  'FIXED',
] satisfies ManageCostPayOptionEnum[];

const ALL_QUALITATIVES = [
  'GOOD_SUNLIGHT',
  'CONVENIENT_TRANSPORT',
  'MANY_AMENITIES',
  'NICE_VIEW',
  'NO_PESTS',
  'AFFORDABLE_PRICE',
  'SAFE_FOR_SOLO',
  'WITH_TERRACE',
  'CLEAN_CONDITION',
  'GOOD_SOUNDPROOFING',
] satisfies ArticleQualitativeItemEnum[];

const ALL_ADDRESS_KINDS = ['GROUP_BUILDING', 'BUILDING', 'LAND'] satisfies AddressKind[];

export const brokerSchema = z.object(
  {
    brokerName: z.string(),
    brokerRegisterNumber: z.string(),
    id: z.string(),
    officeAddress: z.string(),
    officeName: z.string(),
    originalId: z.string(),
    telephoneNumber: z.string(),
  },
  { required_error: '부동산 정보를 입력해주세요.' }
);

export const writerTypeSchema = z.object({
  writerType: z.enum(ALL_WRITER_TYPES, {
    invalid_type_error: '집주인/세입자 여부를 선택해주세요.',
    required_error: '집주인/세입자 여부를 선택해주세요.',
  }),
});

export const buildingUsageSchema = z.object({
  buildingUsage: z.enum(zodEnum(BuildingUsageTypes), {
    invalid_type_error: '건축물 용도를 선택해주세요.',
    required_error: '건축물 용도를 선택해주세요.',
  }),
});

export const salesTypeSchema = z
  .object({
    salesType: z.enum(ALL_SALES_TYPES, {
      required_error: '매물 종류를 선택해주세요.',
      invalid_type_error: '매물 종류를 선택해주세요.',
    }),
    etcSalesType: z.string().max(30, '기타 매물 종류는 30자 이내로 입력해주세요.').nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.salesType === 'ETC' && !form.etcSalesType) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['etcSalesType'],
        message: '기타 매물 종류를 입력해주세요.',
      });
    }
  });

export const buildingNameSchema = z.object({
  buildingName: z.string().max(20, '아파트/건물명은 20자 이내로 작성해주세요.').nullish(),
});

export const addressSchema = z
  .object({
    address: z
      .string({
        required_error: '주소를 입력해주세요.',
      })
      .min(1, '주소를 입력해주세요.'),
  })
  .merge(buildingNameSchema);

export const dongHoSchema = z
  .object({
    addressKind: z.enum(zodEnum(ALL_ADDRESS_KINDS)).nullish(),
    isRentalHouse: z.boolean().nullish(),
    addressDong: z.string().nullish(),
    addressHo: z.string().nullish(),
  })
  .superRefine((address, ctx) => {
    if (
      checkIsDagaguRentalHouse({
        addressKind: address.addressKind ?? null,
        isRentalHouse: address.isRentalHouse ?? false,
      })
    ) {
      if (!address.addressHo) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['addressHo'],
          message: '호수를 입력해주세요.',
        });
      }
    }
  });

export const tradeInputSchema = z
  .object({
    adjustable: z.boolean().nullish(),
    description: z.string().nullish(),
    monthlyPay: z.number().nullish(),
    preferred: z.boolean().nullish(),
    price: z.number().nullish(),
    tradeType: z.enum(zodEnum(TradeTypes)),
  })
  .superRefine((trade, ctx) => {
    const isMonthlyPayable = MonthlyPayableTradeTypes.includes(trade.tradeType);

    if (!isMonthlyPayable && !trade.price) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['price'],
        message: '가격/보증금을 입력해주세요.',
      });
    }

    if (MonthlyPayableTradeTypes.includes(trade.tradeType) && !trade.monthlyPay) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['monthlyPay'],
        message: `${trade.tradeType === 'YEAR' ? '연세' : '월세'}를 입력해 주세요.`,
      });
    }
  });

export const tradesSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    trades: z.array(tradeInputSchema, { required_error: '거래 방식을 선택해주세요.' }).nonempty({
      message: '거래 방식을 선택해주세요.',
    }),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'TENANT' && form.trades.some((trade) => trade.tradeType === 'SHORT')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['trades'],
        message: '세입자는 단기임대를 선택할 수 없어요.',
      });
    }
  });

export const premiumMoneySchema = z.object({
  premiumMoney: z
    .number({
      required_error: '권리금을 입력해주세요.',
      invalid_type_error: '권리금을 입력해주세요.',
    })
    .min(1, '권리금을 입력해주세요.')
    .nullish(),
});

const imageSchema = z.object({
  id: z.string(),
  thumbnail: z.string().nullish(),
  file: z.string().nullish(),
  url: z.string().nullish(),
  medium: z.string().nullish(),
  width: z.number().nullish(),
  height: z.number().nullish(),
});

export const imagesSchema = z.object({
  images: z.array(imageSchema).superRefine((images, ctx) => {
    if (!!images.length && images.length < MIN_IMAGE_COUNT) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['images'],
        message: `매물 사진은 최소 ${MIN_IMAGE_COUNT}장 이상 추가해주세요.`,
      });
    }
  }),
});

export const orientationSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    buildingOrientation: z.enum(ALL_ORIENTATIONS).nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'BROKER' && !form.buildingOrientation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['buildingOrientation'],
        message: '방향을 선택해주세요.',
      });
    }
  });

export const areaSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    salesType: z.enum(ALL_SALES_TYPES).nullable(),
    area: z.string().nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'BROKER' && !form.area) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['area'],
        message: '전용면적을 입력해주세요.',
      });
    }
    // https://daangn.slack.com/archives/C02A29SAFRB/p1707804105236559
    // 주거타입 외에는 최대 면적 제한 제거
    if (
      isOneOfSalesType(form.salesType, ResidentSalesTypes) &&
      form.area &&
      Number(form.area) > 999
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['area'],
        message: '전용면적은 999제곱미터 이하로 입력해주세요.',
      });
    }
  });

export const supplyAreaSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    salesType: z.enum(ALL_SALES_TYPES).nullable(),
    supplyArea: z.string().nullish(),
  })
  .superRefine((form, ctx) => {
    if (
      isOneOfSalesType(form.salesType, ResidentSalesTypes) &&
      form.supplyArea &&
      Number(form.supplyArea) > 999
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['supplyArea'],
        message: '공급면적은 999제곱미터 이하로 입력해주세요.',
      });
    }
  });

export const roomSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    roomCnt: z.number().max(128, '방 수는 128개 이하로 입력해주세요.').nullish(),
    bathroomCnt: z.number().max(128, '욕실 수는 128개 이하로 입력해주세요.').nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType !== 'BROKER') {
      return;
    }

    if (!form.roomCnt) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['roomCnt'],
        message: '방 수를 입력해주세요.',
      });
    }

    if (!form.bathroomCnt) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['bathroomCnt'],
        message: '욕실 수를 입력해주세요.',
      });
    }
  });

export const floorSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    salesType: z.enum(ALL_SALES_TYPES).nullable(),
    floor: z
      .string()
      .pipe(z.coerce.number().max(999, '층 수는 999층 이하로 입력해주세요.'))
      .nullish(),
    topFloor: z
      .string()
      .pipe(z.coerce.number().max(999, '층 수는 999층 이하로 입력해주세요.'))
      .nullish(),
  })
  .superRefine((form, ctx) => {
    if (
      isOneOfSalesType(form.salesType, ResidentSalesTypes) &&
      form.writerType === 'BROKER' &&
      !form.topFloor
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['topFloor'],
        message: '최고층을 입력해주세요.',
      });
    }
  });

export const buildingApprovalDateSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    buildingApprovalDate: z.date().nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'BROKER' && !form.buildingApprovalDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['buildingApprovalDate'],
        message: '사용승인일을 입력해주세요.',
      });
    }
  });

export const areaInfoSchema = areaSchema
  .and(supplyAreaSchema)
  .and(roomSchema)
  .and(floorSchema)
  .and(buildingApprovalDateSchema);

const ManageCostOptionDetailInputSchema = z
  .object({
    fixedCost: z
      .number()
      .int('고정 금액은 만원단위로 입력해주세요.')
      .max(9999, '고정 금액은 최대 9999만원까지 입력할 수 있어요.')
      .nullish(),
    option: z.enum(zodEnum(ManageCostOptions)),
    payOption: z.enum(zodEnum(ALL_MANAGE_COST_PAY_OPTIONS)).nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.payOption === 'FIXED' && isNil(form.fixedCost)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['fixedCost'],
        message: '고정 금액을 입력해주세요.',
      });
    }
  });

export const manageCostSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    etcManageCost: z.number().nullish(),
    includeManageCostOption: z.array(ManageCostOptionDetailInputSchema).nullish(),
    isUnknownManageCost: z.boolean().nullish(),
    manageCost: z.number().nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'BROKER') {
      if (form.isUnknownManageCost) {
        return;
      }

      if (isNil(form.manageCost)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['manageCost'],
          message: '공용 관리비를 입력해주세요.',
        });
      }

      const isAllOptionChecked = ManageCostOptions.every((option) => {
        const manageCostOption = form.includeManageCostOption?.find((o) => o.option === option);

        return !!manageCostOption;
      });

      if (!isAllOptionChecked) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['includeManageCostOption'],
          message: '사용료를 선택해주세요.',
        });
        return;
      }
    } else {
      if (!form.manageCost && form.manageCost !== null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['manageCost'],
          message: '관리비를 입력해주세요.',
        });
      }

      form.includeManageCostOption?.forEach((option, index) => {
        if (
          form.manageCost &&
          option.payOption === 'FIXED' &&
          option.fixedCost &&
          option.fixedCost > form.manageCost
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['includeManageCostOption', index, 'fixedCost'],
            message: '상세 관리비가 입력된 관리비보다 높아요.',
          });
        }
      });
    }
  });

const optionSchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const requiredOptionSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    options: z.array(optionSchema),
    availableParkingSpots: z.number().int('주차 가능 대수는 소수점 입력이 불가능해요.').nullish(),
  })
  .superRefine((form, ctx) => {
    if (
      !RequiredArticleOptionNames.every((name) => {
        const option = form.options.find((o) => o.name === name);
        return !!option;
      })
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['options'],
        message: '입주 가능 조건을 입력해주세요.',
      });
    }

    if (form.writerType === 'BROKER') {
      if (!form.options.find((o) => o.name === 'PARKING')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['options'],
          message: '주차 정보를 입력해주세요.',
          params: { name: 'PARKING' },
        });
      }

      if (
        form.options.find((o) => o.name === 'PARKING')?.value === 'YES' &&
        isNil(form.availableParkingSpots)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['availableParkingSpots'],
          message: '세대당 가능한 주차대수를 알려주세요.',
        });
      }
    }
  });

export const moveInDateSchema = z
  .object({
    writerType: z.enum(ALL_WRITER_TYPES).nullish(),
    moveInDate: z.date().nullish(),
  })
  .superRefine((form, ctx) => {
    if (form.writerType === 'BROKER' && !form.moveInDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['moveInDate'],
        message: '입주 가능일을 입력해주세요.',
      });
    }
  });

export const qualitativeSchema = z.object({
  qualitativeItems: z
    .array(z.enum(zodEnum(ALL_QUALITATIVES)))
    .max(MAX_QUALITATIVE_COUNT, `장점은 ${MAX_QUALITATIVE_COUNT}개까지 선택할 수 있어요.`)
    .nullish(),
});

const emojiRegex = new RegExp(emojiPattern, 'g');

export const replaceEmoji = (
  text: string,
  options: { showToast?: boolean } = { showToast: true }
): string => {
  const { showToast = true } = options;
  const filteredText = text.replace(emojiRegex, '');

  if (showToast && filteredText !== text) {
    actionToast({
      message: '이모티콘은 입력할 수 없어요.',
    });
  }
  return filteredText;
};
