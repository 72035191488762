import CONFIG from 'config';
import { SearchRegion } from 'types/Region';
import { omit, uniqBy } from 'lodash-es';
import { MapConfig } from 'pages/Main';
import { uuidv4 } from './String';
import { CheckListData } from 'types/checklist';
import { NudgeType } from 'store/event';
import { FormArticleType } from 'types/Article';
import { KakaoPlaceDocument } from 'apis/kakao';
import { RegionSearch } from 'store/regionSearch';
import { TradeToolData, TradeToolType } from 'types/tradeTool';
import type { SimpleFormArticleType, SimpleFormType } from 'types/simpleForm';
import type { ClientUserGroup } from 'store/group';
import CommonStorage from 'utils/commonStorage';
import { UserSegment, UserSegmentType } from 'utils/segment';

export const LocalStorageKey = {
  EverWatched: 'everVoted',
  HeaderFlashMy: 'headerFlashMy',
  TempArticle: 'tempArticleFormV7',
  TempInterviewDraft: 'tempInterviewDraft',
  V3Draft: 'v3Draft',
  GAClientID: 'ga:clientId',
  AreaUnit: 'areaUnit',
  MapCurrentPosition: 'mapCurrentPosition',
  SearchChipTooltip: 'searchChipTooltip',
  referrer: 'referrer',
  LastRegionSearch: 'lastRegionSearch',
  RecentSearchPlace: 'recentSearchPlace',
  FirstNewArticleBannerShowed: 'firstNewArticleBannerShowed',
  HideVideoEncodingFailMessage: 'hideVideoEncodingFailMessage',
  ShowNewTradeSearchFilter: 'showNewTradeSearchFilter',
  NudgeHistory: 'nudgeHistory',
  EverVisited: 'everVisited',
  WatchedArticles: 'watchedArticlesV2',
  CalledArticles: 'calledArticles',
  ActionHistory: 'actionHistory',
  ActionCount: 'actionCount',
  ArticleCheckList: 'articleCheckList',
  ArticleTradeTool: 'articleTradeTool',
  ChatProfileNotAskAgainTime: 'chatProfileNotAskAgainTime',
  ExperimentFlags: 'experimentFlags',
  BrokerInfo: 'brokerInfo',
  DirectDealFilter: 'directDealFilter',
  UserSegment: 'userSegment',
  Group: 'group',
} as const;

const SessionStorageKey = {
  referrer: 'referrer',
} as const;

export enum ActionHistoryKey {
  HideSatisfactionBanner = 'hideSatisfactionBanner',
  ClickedBrokerFaqBanner = 'hideBrokerFaqBanner',
  SeenBrokerRealtyHomeBanner = 'seenBrokerRealtyHomeBanner',
  SeenAIConversionSheet = 'seenAIConversionSheet',
  SeenSearchRealtyIdOnboardingSheet = 'seenSearchRealtyIdOnboardingSheet',
  SeenWatchedArticleInfo = 'seenWatchedArticleInfo',
  SeenBizProfileInvitationOnboardingSheet = 'seenBizProfileInvitationOnboardingSheet',
  CreatedLandTitleFeedback = 'createdLandTitleFeedback',
  AgreedBrokerPhoneTerms = 'agreedBrokerPhoneTerms',
}

export enum ActionCountKey {
  BrokerWriteArticle = 'brokerWriteArticle',
  SatisfactionBannerImpression = 'satisfactionBannerImpression',
  BrokerFaqBannerImpression = 'brokerFaqBannerImpression',
  Session = 'session',
  MainMinimumRealtyCarouselImpression = 'mainMinimumRealtyCarouselImpression',
}

type DynamicActionCountKey = `main_marketing_banner_impression_${string}`;

type TempArticleStorageKeyName = Extract<
  keyof typeof LocalStorageKey,
  'TempArticle' | 'TempInterviewDraft'
>;
type TempArticleStorageKey = (typeof LocalStorageKey)[TempArticleStorageKeyName];

const SimpleFormDraftStorageKey = {
  DRAFT_V3: LocalStorageKey.V3Draft,
} satisfies Record<SimpleFormType, (typeof LocalStorageKey)[keyof typeof LocalStorageKey]>;

const MAX_SAVE_RECENT_REGION_LENGTH = 10;
const MAX_GET_RECENT_REGION_LENGTH = 5;

export type RecentSearchPlace =
  | {
      type: 'region';
      data: SearchRegion;
    }
  | {
      type: 'place';
      data: KakaoPlaceDocument;
    };

class RealtyLocalStorage extends CommonStorage<typeof LocalStorageKey> {
  constructor() {
    super(CONFIG.SERVICE_KEY, window.localStorage);
  }

  getEverWatched() {
    return Boolean(this.getItem(LocalStorageKey.EverWatched));
  }

  setEverWatched() {
    this.setItem(LocalStorageKey.EverWatched, 'true');
  }

  getEverVisited() {
    return Boolean(this.getItem(LocalStorageKey.EverVisited));
  }

  setEverVisited() {
    this.setItem(LocalStorageKey.EverVisited, 'true');
  }

  getGaClientId(): string {
    const LEGACY_GA_KEY = 'ga:clientId1';
    const clientId =
      this.getItem(LocalStorageKey.GAClientID) ??
      window.localStorage.getItem(LEGACY_GA_KEY) ??
      uuidv4();

    this.setItem(LocalStorageKey.GAClientID, clientId);

    return clientId;
  }

  setTempArticle(article: FormArticleType, key?: TempArticleStorageKey): void {
    const localStorageKey = key ?? LocalStorageKey.TempArticle;

    this.setItem(localStorageKey, JSON.stringify(article));
  }

  getTempArticle(key?: TempArticleStorageKey) {
    const localStorageKey = key ?? LocalStorageKey.TempArticle;
    const tempArticle = this.getItem(localStorageKey);

    return tempArticle ? (JSON.parse(tempArticle) as FormArticleType) : null;
  }

  clearTempArticle(key?: TempArticleStorageKey) {
    const localStorageKey = key ?? LocalStorageKey.TempArticle;

    this.removeItem(localStorageKey);
  }

  setSimpleFormDraft(
    simpleForm: SimpleFormArticleType,
    formType: SimpleFormType = 'DRAFT_V3'
  ): void {
    this.setItem(SimpleFormDraftStorageKey[formType], JSON.stringify(simpleForm));
  }

  getSimpleFormDraft(formType: SimpleFormType = 'DRAFT_V3'): SimpleFormArticleType | null {
    const draft = this.getItem(SimpleFormDraftStorageKey[formType]);

    return draft ? (JSON.parse(draft) as SimpleFormArticleType) : null;
  }

  clearSimpleFormDraft(formType: SimpleFormType = 'DRAFT_V3') {
    this.removeItem(SimpleFormDraftStorageKey[formType]);
  }

  getLatestMapConfig(): Partial<MapConfig> {
    const mapConfig = this.getItem(LocalStorageKey.MapCurrentPosition);
    return mapConfig ? (JSON.parse(mapConfig) as MapConfig) : {};
  }

  setLatestMapConfig(mapConfig: MapConfig) {
    this.setItem(
      LocalStorageKey.MapCurrentPosition,
      JSON.stringify({
        ...mapConfig,
      })
    );
  }

  getRecentSearchPlace(filter?: RecentSearchPlace['type']) {
    const items = this.getItem(LocalStorageKey.RecentSearchPlace);
    const places = items ? (JSON.parse(items) as RecentSearchPlace[]) : [];
    const filtered = filter ? places.filter((place) => place.type === filter) : places;

    return filtered.slice(0, MAX_GET_RECENT_REGION_LENGTH);
  }

  addRecentSearchPlace(region: RecentSearchPlace) {
    const regions = this.getRecentSearchPlace();
    const newRegions = uniqBy([region, ...regions], (item) => `${item.type}_${item.data.id}`).slice(
      0,
      MAX_SAVE_RECENT_REGION_LENGTH
    );

    this.setItem(LocalStorageKey.RecentSearchPlace, JSON.stringify(newRegions));
  }

  setLastRegionSearch(regionSearch: RegionSearch) {
    this.setItem(LocalStorageKey.LastRegionSearch, JSON.stringify(regionSearch));
  }

  getLastRegionSearch(): RegionSearch | null {
    const items = this.getItem(LocalStorageKey.LastRegionSearch);

    return items ? (JSON.parse(items) as RegionSearch) : null;
  }

  setFirstNewArticleBannerShowed() {
    this.setItem(LocalStorageKey.FirstNewArticleBannerShowed, 'true');
  }

  getFirstNewArticleBannerShowed() {
    return this.getItem(LocalStorageKey.FirstNewArticleBannerShowed) === 'true';
  }

  setHideVideoEncodingFailMessage(videoId: string) {
    const hideMap = this.getHideVideoEncodingFailMessage();

    this.setItem(
      LocalStorageKey.HideVideoEncodingFailMessage,
      JSON.stringify({ ...hideMap, [videoId]: true })
    );
  }

  getHideVideoEncodingFailMessage(): { [key: string]: boolean } {
    const hideMap = this.getItem(LocalStorageKey.HideVideoEncodingFailMessage);

    return hideMap ? JSON.parse(hideMap) : {};
  }

  getCheckList() {
    const checkListString = this.getItem(LocalStorageKey.ArticleCheckList);
    const checkList = checkListString
      ? (JSON.parse(checkListString) as { [key: string]: CheckListData })
      : null;

    return checkList;
  }

  setCheckList(articleId: string, checkListData: Partial<CheckListData>) {
    const prevCheckList = this.getCheckList();

    this.setItem(
      LocalStorageKey.ArticleCheckList,
      JSON.stringify({
        ...prevCheckList,
        [articleId]: {
          ...prevCheckList?.[articleId],
          ...checkListData,
        },
      })
    );
  }

  getTradeTool() {
    const tradeToolString = this.getItem(LocalStorageKey.ArticleTradeTool);
    const tradeTool = tradeToolString
      ? (JSON.parse(tradeToolString) as { [key: string]: TradeToolData })
      : null;

    return tradeTool;
  }

  getTradeToolByType(articleId: string, type: TradeToolType) {
    const tradeTool = this.getTradeTool();

    return tradeTool?.[articleId]?.[type];
  }

  setTradeToolByType<T extends TradeToolType>(articleId: string, type: T, data: TradeToolData[T]) {
    const prevTradeTool = this.getTradeTool();

    this.setItem(
      LocalStorageKey.ArticleTradeTool,
      JSON.stringify({
        ...prevTradeTool,
        [articleId]: {
          ...prevTradeTool?.[articleId],
          [type]: {
            ...prevTradeTool?.[articleId]?.[type],
            ...data,
          },
        },
      })
    );
  }

  getNudgeHistory(): { [key in NudgeType]: string } {
    const item = this.getItem(LocalStorageKey.NudgeHistory);

    return item ? JSON.parse(item) : {};
  }
  setNudgeHistory(type: NudgeType) {
    const history = LocalStorage.getNudgeHistory();
    const now = new Date();

    this.setItem(
      LocalStorageKey.NudgeHistory,
      JSON.stringify({
        ...history,
        [type]: now.toString(),
      })
    );
  }

  getWatchedArticles(): { [key: string]: Date } {
    const item = this.getItem(LocalStorageKey.WatchedArticles);
    const watchedArticles = item ? JSON.parse(item) : {};

    return Object.fromEntries(
      Object.entries(watchedArticles).map(([key, value]) => [key, new Date(value as string)])
    );
  }
  setWatchedArticles(articles: { [key: string]: Date }) {
    const watchedArticles = LocalStorage.getWatchedArticles();

    this.setItem(
      LocalStorageKey.WatchedArticles,
      JSON.stringify({
        ...watchedArticles,
        ...articles,
      })
    );
  }

  getActionHistories(): Record<ActionHistoryKey, number | undefined> {
    const item = this.getItem(LocalStorageKey.ActionHistory);

    return item ? JSON.parse(item) : {};
  }

  getActionHistory(key: ActionHistoryKey) {
    return this.getActionHistories()?.[key];
  }

  setActionHistory(key: ActionHistoryKey) {
    const histories = this.getActionHistories();
    this.setItem(
      LocalStorageKey.ActionHistory,
      JSON.stringify({
        ...histories,
        [key]: Date.now(),
      })
    );
  }

  resetActionHistory(key: ActionHistoryKey) {
    const histories = this.getActionHistories();
    this.setItem(LocalStorageKey.ActionHistory, JSON.stringify(omit(histories, [key])));
  }

  getActionCounts(): Record<ActionCountKey | DynamicActionCountKey, number | undefined> {
    const item = this.getItem(LocalStorageKey.ActionCount);

    return item ? JSON.parse(item) : {};
  }

  getActionCount(key: ActionCountKey | DynamicActionCountKey) {
    return this.getActionCounts()?.[key] ?? 0;
  }

  addActionCount(key: ActionCountKey | DynamicActionCountKey) {
    const counts = this.getActionCounts();

    this.setItem(
      LocalStorageKey.ActionCount,
      JSON.stringify({
        ...counts,
        [key]: Math.min((counts[key] ?? 0) + 1, Number.MAX_SAFE_INTEGER),
      })
    );
  }

  setChatProfileNotAskAgainTime() {
    this.setItem(LocalStorageKey.ChatProfileNotAskAgainTime, JSON.stringify(Date.now()));
  }

  getChatProfileNotAskAgainTime() {
    const item = this.getItem(LocalStorageKey.ChatProfileNotAskAgainTime);

    return item ? (JSON.parse(item) as number) : null;
  }

  setGroup<T extends keyof ClientUserGroup>(type: T, group: ClientUserGroup[T]) {
    const prevGroup = JSON.parse(this.getItem(LocalStorageKey.Group) ?? '{}') as ClientUserGroup;

    this.setItem(
      LocalStorageKey.Group,
      JSON.stringify({
        ...prevGroup,
        [type]: group,
      })
    );
  }

  getGroup<T extends keyof ClientUserGroup>(type: T): ClientUserGroup[T] | null {
    const item = this.getItem(LocalStorageKey.Group);

    return item ? (JSON.parse(item) as ClientUserGroup)[type] : null;
  }

  removeBrokerInfo() {
    this.removeItem(LocalStorageKey.BrokerInfo);
  }

  getDirectDealFilter() {
    const item = this.getItem(LocalStorageKey.DirectDealFilter);

    return item ? (JSON.parse(item) as boolean) : null;
  }

  setDirectDealFilter(isDirectDeal: boolean) {
    this.setItem(LocalStorageKey.DirectDealFilter, JSON.stringify(isDirectDeal));
  }

  getUserSegments() {
    const item = this.getItem(LocalStorageKey.UserSegment);

    const segments = item ? (JSON.parse(item) as UserSegment) : null;
    const validSegments = Object.fromEntries(
      Object.entries(segments ?? {}).filter(([key]) => key in UserSegmentType)
    );

    return validSegments as UserSegment;
  }

  addUserSegment(segment: UserSegmentType) {
    const segments = this.getUserSegments();

    this.setItem(
      LocalStorageKey.UserSegment,
      JSON.stringify({
        ...segments,
        [segment]: true,
      })
    );
  }

  removeUserSegment(segment: UserSegmentType) {
    const segments = this.getUserSegments();

    delete segments?.[segment];

    this.setItem(LocalStorageKey.UserSegment, JSON.stringify(segments));
  }

  setUserSegments(segments: Partial<UserSegment>) {
    const prevSegments = this.getUserSegments();

    this.setItem(
      LocalStorageKey.UserSegment,
      JSON.stringify({
        ...prevSegments,
        ...segments,
      })
    );
  }
  addCalledArticle(articleId: string) {
    this.setCalledArticles({
      [articleId]: new Date(),
    });
  }
  getCalledArticles(): { [key: string]: Date } {
    const item = this.getItem(LocalStorageKey.CalledArticles);
    const calledArticles = item ? JSON.parse(item) : {};

    return Object.fromEntries(
      Object.entries(calledArticles).map(([key, value]) => [key, new Date(value as string)])
    );
  }
  setCalledArticles(articles: { [key: string]: Date }) {
    const calledArticles = LocalStorage.getCalledArticles();

    this.setItem(
      LocalStorageKey.CalledArticles,
      JSON.stringify({
        ...calledArticles,
        ...articles,
      })
    );
  }
}

class RealtySessionStorage extends CommonStorage<typeof SessionStorageKey> {
  constructor() {
    super(CONFIG.SERVICE_KEY, window.sessionStorage);
  }
  setReferrer(referrer: string) {
    this.setItem('referrer', referrer);
  }
  getReferrer() {
    return this.getItem('referrer');
  }
}

const LocalStorage = new RealtyLocalStorage();
const SessionStorage = new RealtySessionStorage();

export { LocalStorage, SessionStorage };
