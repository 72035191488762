import { Box, HStack, Stack, StackDivider } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconRetryThin } from '@seed-design/react-icon';
import { vars } from '@seed-design/design-token';
import BoxButton from 'components/Base/BoxButton';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import { zIndices } from 'constants/zIndices';
import withDev from 'hoc/withDev';
import useDisclosure from 'hooks/useDisclosure';
import React, { FormEvent, useEffect, useRef, useState, type ChangeEvent, useMemo } from 'react';
import sessionState from 'store/session';
import { checkIsAmplitudeTargetUser } from 'utils/amplitude';
import DoorBottomSheet from 'components/Dev/DoorBottomSheet';
import useCustomFlow from 'hooks/useCustomFlow';
import { IS_PROD_TEST, IS_REAL } from 'config';
import { overwriteQuery } from 'utils/Url';
import TextField from 'components/Base/TextField';
import { LocalStorage, SessionStorage } from 'utils/Storage';
import GroupMenuItem from 'components/Dev/GroupMenuItem';
import MainListMenuItem from 'components/Dev/MainListMenuItem';
import Text from 'components/Base/Text';
import { debugDateState } from 'store/debug';
import { useToday } from 'hooks/useToday';
import { Slider } from '@daangn/sprout-components-slider';
import { useAtomValue, useSetAtom } from 'jotai';

const DEFAULT_FONT_SIZE = 16;

const MagicButton = () => {
  const [fontSize, setFontSize] = useState(
    Number(document.documentElement.style.fontSize || DEFAULT_FONT_SIZE)
  );
  const [pos, setPos] = useState({
    left: 10,
    top: window.innerHeight - 100,
  });
  const startPos = useRef({ x: 0, y: 0 });
  const [isDevOpen, devOpenHandler] = useDisclosure();
  const [isDoorOpen, doorHandler] = useDisclosure();
  const { user } = useAtomValue(sessionState);
  const setDebugDate = useSetAtom(debugDateState);
  const today = useToday();
  const { push } = useCustomFlow();
  const localDateTime = useMemo(() => {
    const now = new Date(today);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    return now.toISOString().slice(0, 16);
  }, [today]);

  const handleStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const { left, top } = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const touch = e.touches[0];

    startPos.current.x = left - touch.clientX;
    startPos.current.y = top - touch.clientY;
  };

  const handleMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const { height, width } = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const touch = e.touches[0];

    setPos({
      left: Math.min(Math.max(startPos.current.x + touch.clientX, 0), window.innerWidth - width),
      top: Math.max(Math.min(startPos.current.y + touch.clientY, window.innerHeight - height), 0),
    });
  };

  const handleFontSizeChange = (v: number) => {
    setFontSize(v);
    document.documentElement.style.fontSize = `${v}px`;
  };

  const handleReferrerChange = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const referrer = e.currentTarget.referrer.value;

    if (referrer) {
      window.location.href = overwriteQuery(window.location.href, {
        referrer,
      });
    }
  };

  const handleBrokerChange = () => {
    window.location.href = overwriteQuery(window.location.href, {
      is_broker: 'true',
    });
  };

  const handleDebugDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setDebugDate(new Date(date));
  };

  useEffect(() => {
    // iOS에서 height 바로 못잡는 이슈 있음
    const timer = setTimeout(() => {
      setPos((prev) => ({ ...prev, top: window.innerHeight - 100 }));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {IS_PROD_TEST && (
        <Box
          position="fixed"
          top="env(safe-area-inset-top, 0px)"
          left="50%"
          transform="translateX(-50%)"
          zIndex={zIndices.outermost}
        >
          <Text bg="black" variant="caption2Bold" color="danger">
            ⚠️ 프로덕션 테스트 환경 주의
          </Text>
        </Box>
      )}
      <HStack
        css={css`
          position: fixed;
          top: ${pos.top}px;
          left: ${pos.left}px;

          color: ${vars.$scale.color.gray900};

          z-index: ${zIndices.outermost};
        `}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        px={5}
      >
        <BoxButton size="xsmall" variant="primary" onClick={devOpenHandler.onOpen}>
          DEV
        </BoxButton>
        <RoundButton onClick={() => window.location.reload()}>
          <IconRetryThin width={24} height={24} />
        </RoundButton>
      </HStack>
      <BottomSheet
        css={css`
          max-height: 80vh;
        `}
        isOpen={isDevOpen}
        onClose={devOpenHandler.onClose}
      >
        <Stack
          onClick={devOpenHandler.onClose}
          divider={<StackDivider borderColor={vars.$semantic.color.divider1} />}
        >
          <MenuItem
            onClick={() => {
              devOpenHandler.onClose();
              doorHandler.onOpen();
            }}
          >
            🚪 어디로든문
          </MenuItem>
          {!IS_REAL && (
            <MenuItem onClick={() => push('alpha_login', {})}>
              테스트 계정 로그인 (프로덕션 포함)
            </MenuItem>
          )}
          <MenuItem>
            <div onClick={(e) => e.stopPropagation()}>
              <MainListMenuItem />
            </div>
          </MenuItem>
          <MenuItem>
            <Stack spacing={8}>
              <label>빅폰트 {fontSize}px</label>
              <div style={{ padding: '0 8px' }}>
                <Slider
                  value={fontSize}
                  minValue={16}
                  maxValue={30}
                  step={2}
                  onChange={(v) => handleFontSizeChange(v as number)}
                />
              </div>
            </Stack>
          </MenuItem>
          <MenuItem>
            <Stack spacing={8} onClick={(e) => e.stopPropagation()}>
              <label>날짜 변경 (배너 테스트 등)</label>
              <input type="datetime-local" value={localDateTime} onChange={handleDebugDateChange} />
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleBrokerChange}>중개사로 들어오기</MenuItem>
          <MenuItem>
            <Stack spacing={8} onClick={(e) => e.stopPropagation()}>
              <label>레퍼러 바꾸기</label>
              <form onSubmit={handleReferrerChange}>
                <HStack alignItems="flex-start">
                  <TextField
                    defaultValue={SessionStorage.getReferrer() ?? ''}
                    size="small"
                    name="referrer"
                    flexGrow={1}
                  />
                  <BoxButton type="submit">변경</BoxButton>
                </HStack>
              </form>
            </Stack>
          </MenuItem>
          <MenuItem>
            <div onClick={(e) => e.stopPropagation()}>
              <GroupMenuItem />
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => window.alert(JSON.stringify(LocalStorage.getUserSegments(), null, 2))}
          >
            내 세그먼트 확인
          </MenuItem>
          <MenuItem
            onClick={async () => {
              const isTarget = await checkIsAmplitudeTargetUser(
                user?.id,
                LocalStorage.getUserSegments()
              );

              window.alert(isTarget ? 'O' : 'X');
            }}
          >
            앰플리튜드 샘플링 여부 확인
          </MenuItem>
        </Stack>
      </BottomSheet>
      <DoorBottomSheet isOpen={isDoorOpen} onClose={doorHandler.onClose} />
    </>
  );
};

const MenuItem = styled.div`
  padding: 16px 8px;

  ${vars.$semantic.typography.label2Bold}
`;

const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: inherit;
  background-color: ${vars.$scale.color.gray100};
  border-radius: 100%;
  padding: 5px;
`;

export default withDev(MagicButton);
