import { HStack, Spacer } from '@chakra-ui/layout';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { zIndices } from 'constants/zIndices';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { actionToast, TActionToastState } from 'utils/actionToast';
import Logger from 'utils/Logger';
import { getSafeArea } from 'utils/style';

type Props = {
  toastState: TActionToastState;
};

const OFFSET_GAP = 10;
const SAFE_AREA_INSET_BOTTOM = getSafeArea().bottom;

const ActionToast = ({ toastState }: Props) => {
  const offset = (toastState.offset ?? 0) + OFFSET_GAP;
  const bottom = offset > SAFE_AREA_INSET_BOTTOM ? offset : SAFE_AREA_INSET_BOTTOM + OFFSET_GAP;

  useEffect(() => {
    if (toastState.event) {
      Logger.impression(
        {
          name: `show_toast`,
          description: `공통 toast 노출`,
          event_type: toastState.event.type,
          ...toastState.event.params,
        },
        toastState.event.option
      );
    }
  }, []);

  useEffect(() => {
    if (toastState.duration) {
      const timer = setTimeout(() => {
        actionToast.close();
      }, toastState.duration);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Wrapper
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      style={{
        bottom,
      }}
      onClick={() => actionToast.close()}
    >
      <HStack spacing={10}>
        {toastState.icon && <div>{toastState.icon}</div>}
        <Description>{toastState.message}</Description>
        <Spacer />
        {toastState.action && (
          <Action
            onClick={(e) => {
              toastState.action?.onClick?.(e);
              toastState.event &&
                Logger.click(
                  {
                    name: `click_toast_action`,
                    description: `공통 toast 액션 클릭`,
                    event_type: toastState.event.type,
                    ...toastState.event.params,
                  },
                  toastState.event.option
                );
            }}
          >
            {toastState.action.label}
          </Action>
        )}
      </HStack>
    </Wrapper>
  );
};

export default ActionToast;

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 16px;
  min-height: 44px;

  padding: 11.5px 16px;
  border-radius: 6px;
  background: ${vars.$scale.color.gray900};
  z-index: ${zIndices.toast};

  transition: bottom 0.2s ease-in-out;
`;

const Description = styled.p`
  ${vars.$semantic.typography.bodyL2Regular};
  color: ${vars.$scale.color.gray00};

  word-wrap: break-word;
  word-break: keep-all;
`;

const Action = styled.button`
  flex-shrink: 0;
  padding: 0;

  ${vars.$semantic.typography.label3Bold}
  color: ${vars.$semantic.color.primary};
`;
