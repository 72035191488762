import { css } from '@emotion/react';

export const textEllipsis = (line?: number) => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  ${line && `-webkit-line-clamp: ${line};`}
`;

export const hidden = css`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
`;

export const lineBreakText = css`
  white-space: pre-wrap;
  word-break: break-word;

  line-height: 150%;
`;

export const wordBreakText = css`
  word-wrap: break-word;
  word-break: keep-all;
`;

export const horizontalScroll = css`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const scrollOverMargin = (margin?: number) => css`
  margin: 0 -${margin || 16}px;
  padding: 0 ${margin || 16}px;
`;

export const expandClickArea = (size = 5) => css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: -${size}px;
  }
`;
