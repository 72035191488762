import React from 'react';
import { isElementVisible } from 'utils/dom';
import type { LoggerComponentProps } from 'utils/Logger';
import { uuidv4 } from 'utils/String';

export type TActionToastState = {
  id: string;
  message: React.ReactNode;
  icon?: React.ReactNode;
  action?: {
    label: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
  duration?: number;
  offset?: number;
} & LoggerComponentProps;

type TListener = (state: TActionToastState | null) => void;

class ActionToastStore {
  private listeners: Set<TListener> = new Set();
  private toastState: TActionToastState | null = null;
  private offsetElements: Set<HTMLElement> = new Set();

  listen = (listener: TListener) => {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  };

  emit = () => this.listeners.forEach((listener) => listener(this.getState()));

  getState = () => this.toastState;

  getOffset = () => findOffset([...this.offsetElements]);

  updateOffset = () => {
    if (this.toastState) {
      this.toastState = {
        ...this.toastState,
        offset: this.getOffset(),
      };
      this.emit();
    }
  };

  show = (state: Omit<TActionToastState, 'id' | 'offset'>) => {
    this.toastState = {
      ...state,
      offset: this.getOffset(),
      id: uuidv4(),
    };
    this.emit();
  };

  close = () => {
    this.toastState = null;
    this.emit();
  };

  upsertOffsetElement = (element: HTMLElement) => {
    this.offsetElements.add(element);
    this.updateOffset();
  };

  deleteOffsetElement = (element: HTMLElement) => {
    this.offsetElements.delete(element);
    this.updateOffset();
  };
}

export const actionToastStore = new ActionToastStore();

const actionToast = (state: Parameters<typeof actionToastStore.show>[0]) => {
  actionToastStore.show(state);
};

actionToast.close = actionToastStore.close;

export { actionToast };

function findOffset(els: HTMLElement[]) {
  let maxOffset = 0;

  els.filter(isElementVisible).forEach((element) => {
    const rect = element.getBoundingClientRect();
    const offset = window.innerHeight - (rect.bottom - rect.height);
    if (offset > maxOffset) {
      maxOffset = offset;
    }
  });

  return maxOffset;
}
