import { css, SerializedStyles } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import React from 'react';
import { compose } from './Function';

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const maskingPhoneNumber = (content: string): string => {
  return content
    .replace(/\d{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,11}/, ' ***-****-**** ')
    .replace(/[공일]{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,}/, ' ***-****-**** ')
    .replace(/(0\d{2})[.-\s](\d{3,4})[.-\s](\d{3,4})/g, ' ***-****-**** ');
};

const maskingKakaoId = (content: string): string => {
  return content
    .replace(/http[s]?:\/\/open\.kakao\.com(?:\/petitions)?(?:\/[a-zA-Z0-9.]+)*/g, '*****')
    .replace(/(?:카톡|카카오|kakao)[\S ]*[a-zA-Z0-9]{5,10}/g, '*****');
};

export const maskingContent = (content: string): string => {
  return compose(maskingKakaoId, maskingPhoneNumber)(content);
};

export const getCoordinateString = (coordinate: { lat: string; lon: string }) => {
  if (!coordinate) return '';
  return coordinate.lat.slice(0, 10) + coordinate.lon.slice(0, 10);
};

export const trimVideoName = (name: string, maxLength = 20) => {
  if (!name) {
    return '';
  }

  if (name.length > maxLength) {
    const splitted = name.split('.');
    const extension = splitted.pop();
    const filename = splitted.join('.');

    const trimmedFileName = `${filename.slice(0, 8)}..${filename.slice(-2)}`;

    return `${trimmedFileName}.${extension}`;
  }
  return name;
};

const escapeStringRegexp = (string: string) => {
  // https://github.com/sindresorhus/escape-string-regexp
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

function replaceRegex(
  text: string,
  regex: RegExp,
  option: { tag: keyof React.ReactHTML; style?: SerializedStyles }
): React.ReactNode[];
function replaceRegex(
  text: string,
  regex: RegExp,
  option: (matchedText: string) => React.ReactNode
): React.ReactNode[];
function replaceRegex(text: any, regex: any, option: any) {
  const splitted = text.split(regex);

  const template =
    typeof option === 'function'
      ? option
      : (matchedText: string) => {
          const Tag = option.tag;

          return <Tag css={option.style}>{matchedText}</Tag>;
        };

  for (let i = 1; i < splitted.length; i += 2) {
    splitted[i] = template(splitted[i]);
  }

  return splitted.map((comp: any, index: number) => (
    <React.Fragment key={index}>{comp}</React.Fragment>
  ));
}

export const highlightText = ({
  fullText,
  boldText,
  color = vars.$semantic.color.primary,
  tag = 'b',
}: {
  fullText: string;
  boldText?: string | RegExp;
  color?: string;
  tag?: keyof React.ReactHTML;
}) => {
  if (!boldText) {
    return fullText;
  }
  const bold =
    boldText instanceof RegExp ? boldText : new RegExp(`(${escapeStringRegexp(boldText)})`, 'gi');

  return replaceRegex(fullText, bold, {
    tag,
    style: css`
      color: ${color};
    `,
  });
};
