import { atom, type PrimitiveAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { SalesTypeEnum } from 'types/schemaEnums';

export type NudgeEvent =
  // 룰 체크용 이벤트
  | 'FirstVisit' // 방문 체크
  | 'ClickLike' // 관심 매물이 있는 경우
  | 'IsWriter' // 작성한 게시글이 있는 경우
  | 'ScrollBottom' // 리스트 최하단에 내려간 경우
  | 'MapDetail' // 매물 상세에서 지도 클릭
  | 'CopyAddress' // 주소 복사하기 클릭
  | 'ChangeRegion' // range3 & 최하단으로 내렸을때
  // 버튼 클릭
  | 'ClickArticle' // 동일 타입 매물 3회 이상 클릭
  | 'ClickRegionButton' // 지역 변경 영역을 눌렀을 경우
  | 'ClickFilterButton' // 필터 버튼을 눌렀을 경우
  | 'ClickMapButton' // 지도 보기 버튼을 눌렀을 경우
  | 'ClickMyPageButton'; // 마이 페이지 아이콘을 눌렀을 경우

export type EventParams =
  | { key: Exclude<NudgeEvent, 'ClickArticle'>; value: boolean }
  | {
      key: 'ClickArticle';
      value: SalesTypeEnum;
    };

export type NudgeType = 'Filter' | 'Map' | 'Region' | 'MyPage' | 'VotePriceDown';

type BooleanType<T> = T extends T ? { type: T; value: boolean } : never;

export type NudgeState =
  | BooleanType<Exclude<NudgeType, 'Filter'>>
  | {
      type: 'Filter';
      value: false | { salesType: SalesTypeEnum };
    };

// https://github.com/pmndrs/jotai/issues/997#issuecomment-1030944042
export const tooltipState = atomFamily((_type: any) => {
  return atom(false);
}) as {
  <Type extends NudgeType>(
    param: Type
  ): PrimitiveAtom<Extract<NudgeState, { type: Type }>['value']>;
  remove<Type extends NudgeType>(param: Type): void;
  setShouldRemove<Type extends NudgeType>(
    shouldRemove: ((createdAt: number, param: Type) => boolean) | null
  ): void;
};
