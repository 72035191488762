import { CacheConfig, RequestParameters, Variables } from 'relay-runtime';
import { RealtyInstance as api } from 'apis/instance';
import { GraphQLError } from 'types/global';
import { captureException, withScope } from '@sentry/react';
import { checkIsServiceError } from 'utils/relay';
import { IS_LOCAL } from 'config';
import type { AxiosRequestConfig } from 'axios';
import queryMap from 'persisted-queries.json';

const USE_PERSIST = !IS_LOCAL;

type FetchQueryParams = {
  requestParams: RequestParameters;
  variables: Variables;
  axiosConfig: AxiosRequestConfig;
};

const fetchBaseQuery = ({ requestParams, variables, axiosConfig }: FetchQueryParams) => {
  return api.post(
    '/graphql',
    {
      query: requestParams?.text,
      variables,
    },
    axiosConfig
  );
};

const fetchPersistQuery = ({ requestParams, variables, axiosConfig }: FetchQueryParams) => {
  if (requestParams.operationKind === 'mutation') {
    return api.post(
      '/graphql',
      {
        query: requestParams?.id,
        variables,
      },
      axiosConfig
    );
  }

  return api.get('/persisted-graphql', {
    params: {
      operationId: requestParams?.id,
      variables: JSON.stringify(variables),
    },
    ...axiosConfig,
  });
};

async function fetchGraphQL(
  requestParams: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig
) {
  const query = USE_PERSIST
    ? queryMap[requestParams?.id as keyof typeof queryMap]
    : requestParams?.text;
  const axiosConfig = {
    signal: cacheConfig.metadata?.['signal'] as AbortSignal,
    headers: {
      'Content-Type': 'application/json',
    },
    realtyCustomConfig: {
      graphqlRequestParams: requestParams,
    },
  };

  const fetchQuery = USE_PERSIST ? fetchPersistQuery : fetchBaseQuery;

  // Fetch data from GitHub's GraphQL API:
  const { data, config } = await fetchQuery({ requestParams, variables, axiosConfig });

  data.errors?.forEach((error: GraphQLError) => {
    withScope((scope) => {
      scope.setTag('kind', requestParams.operationKind);
      scope.setTag('operationName', requestParams.name);
      scope.setExtra('query', query);
      scope.setExtra('variables', variables);
      scope.setExtra('errorResponse', error);
      scope.setExtra('headers', config.headers);

      const exception = new Error(error?.message || '');
      exception.name = `Relay Error - [${requestParams.operationKind}][${requestParams.name}]`;

      if (checkIsServiceError(error)) {
        scope.setLevel('warning');
      }

      captureException(exception);

      if (error.extensions) {
        error.extensions.sentryHandled = true;
      }
    });
  });

  return data;
}

export default fetchGraphQL;
