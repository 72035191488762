/**
 * @generated SignedSource<<d904ffc7bd167678b19893fb7ac5eccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bizProfileBroker_viewer$data = {
  readonly bizProfileBroker: {
    readonly bizProfile: {
      readonly businessRegistrationNumber: string;
      readonly createdAt: string;
      readonly id: string;
      readonly jibunAddress: string;
      readonly licenseOwnerContact: string;
      readonly licenseOwnerName: string;
      readonly licenseRegistrationNumber: string;
      readonly localProfileId: string;
      readonly name: string;
      readonly originalId: string;
      readonly thumbImageUrl: string;
    };
    readonly id: string;
    readonly userPhoneNumber: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "bizProfileBroker_viewer";
};
export type bizProfileBroker_viewer$key = {
  readonly " $data"?: bizProfileBroker_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"bizProfileBroker_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bizProfileBroker_viewer"
};

(node as any).hash = "058daef7f131d40446fffa1307180072";

export default node;
